import React from 'react'
import styled from 'styled-components'
import { FlexDiv } from '../design-system/FlexDiv'

const Pages = styled.h1`
  font-family: CentraNo2-Book;
  font-size: 45px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  // line-height: 2.44;
  letter-spacing: -0.68px;
  text-align: left;
  color: #ffffff;
  margin-right: 16px;
`

const Rectangle = styled.div`
  width: 27.5em;
  height: 25em;
  opacity: 0.9;
  background-color: #1c1c1c;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 150px;
  display: flex;
  flex-direction: column;
  padding: 0 35px;
`

const Info = styled.div`
  margin: 0px;
  color: white;
  display: flex;
  // background: red;
  justify-content: space-between;
`

const NoPages = styled.h1`
  font-family: CentraNo2-thin;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  // line-height: 4.58;
  letter-spacing: -0.36px;
  text-align: left;
  color: #adadad;
`

const Arrows = styled.div`
  margin-top: 10%;
  // margin-left: 50px;
`

const OurHistoryWrapper = styled.div`
  max-width: 90%;
  margin: auto;
  padding: 2rem 5rem;
  display: inline-flex;
`

const OurHistoryTitle = styled.h3`
  font-family: CentraNo2-Bold;
`

const OurHistoryText = styled.p`
  font-family: CentraNo2-Book;
  font-size: 0.9em;
  line-height: 1.5;
`

export const TermsPage: React.FunctionComponent = () => {
  return (
    <OurHistoryWrapper className="ourHistoryWrapper termsPage">
      <FlexDiv style={{ flexFlow: 'column' }}>
        <FlexDiv className="informationImgWrapper">
          <FlexDiv
            style={{
              flex: 2,
              flexDirection: 'column',
              placeSelf: 'flex-start',
            }}
          >
            <OurHistoryTitle style={{ marginTop: 0 }}>
              Terms of Service
            </OurHistoryTitle>
            <OurHistoryText style={{ flex: 1 }}>
              Welcome to our website. Please note that any access, use, or
              participation in this website constitutes an immediate acceptance
              and agreement to be bound by the terms and provision of this
              agreement. In addition, the use of particular services provided by
              this website shall subject the user to any posted guidelines or
              rules applicable to such services, which may be posted and
              modified from time to time. All such guidelines or rules are
              hereby incorporated by reference into the Terms of Service. The
              use of this website is not recommended and highly discouraged for
              those in disagreement with any part of the Terms of Service.
            </OurHistoryText>
            <OurHistoryText style={{ flex: 1 }}>
              From herein, the terms 'us' or 'we' or 'our' will refer to the
              owner of the website. The terms 'you' or 'your' will refer to the
              user or viewer of this website.
            </OurHistoryText>
            <OurHistoryText style={{ flex: 1 }}>
              The use of this website is subject to the following terms of use:
            </OurHistoryText>

            <OurHistoryText style={{ flex: 1 }}>
              This website and its components are offered for informational
              purposes only; neither we nor any third parties provide any
              warranty or guarantee as to the accuracy, timeliness, performance,
              completeness or suitability of the information and materials found
              or offered on this website for any particular purpose. We shall
              not be held responsible or liable for the accuracy, usefulness or
              availability of any information transmitted or made available via
              the website, and shall not be responsible or liable for any error
              or omissions in that information. The information in this website
              is subject to change without notice.
            </OurHistoryText>
            <OurHistoryText style={{ flex: 1 }}>
              Your use of any information or materials in this website is
              entirely at your own risk, for which we shall not be held liable.
              It shall be your own responsibility to ensure that any products,
              services or information available through this website meets your
              specific requirements.
            </OurHistoryText>
            <OurHistoryText style={{ flex: 1 }}>
              This website uses “cookies” to monitor browsing preferences.
              Cookies are small pieces of information, including but not limited
              to, personal information stored by your browser on your computer’s
              hard drive. Personal information may be stored by us for use by
              third parties related to this website. Personal information
              includes but is not limited to, User Name, Password, and E-mail
              Address credentials used during registration for this website.
            </OurHistoryText>
            <OurHistoryText style={{ flex: 1 }}>
              Our website contains registration forms which require users to
              provide contact information (first name, last name, phone number),
              unique identifiers (e-mail address), and demographic information
              (postal code, city, region). This information will be stored,
              used, and shared within our brokerage and between members of our
              brokerage to serve your informational needs.
            </OurHistoryText>
            <OurHistoryText style={{ flex: 1 }}>
              This website contains material which is owned by or licensed to us
              by InCom Web & e-Marketing Solutions. This material includes, but
              is not limited to, the design, layout, look, appearance, graphics,
              and content in this website. Reproduction of said materials is
              prohibited other than in accordance with the copyright notice,
              which forms part of these terms and conditions. The use of this
              website is subject to the following terms of use:
            </OurHistoryText>
            <OurHistoryText style={{ flex: 1 }}>
              All trademarks reproduced in this website, which are not the
              property of, or licensed to the operator, are acknowledged in the
              website.
            </OurHistoryText>
            <OurHistoryText style={{ flex: 1 }}>
              Unauthorized use of this website may give rise to a claim for
              damages and/or be a criminal offence.
            </OurHistoryText>

            <OurHistoryText style={{ flex: 1 }}>
              From time to time, this website may also include links to other
              website(s) or post(s). These links are provided for your
              convenience to provide further information. They do not signify
              that we endorse the website(s) or post(s). We have no
              responsibility for the content of the linked website(s) or
              post(s). We the right to add, modify, remove all material of the
              website in its sole and absolute discretion including, without
              limitation, links and posts.
            </OurHistoryText>
            <OurHistoryText style={{ flex: 1 }}>
              Your use of this website and any dispute arising out of such use
              of the website is subject to the laws of Canada.
            </OurHistoryText>
            <OurHistoryText style={{ flex: 1 }}>Disclaimer</OurHistoryText>
            <OurHistoryText style={{ flex: 1 }}>
              The information contained on this website is based in whole or in
              part on information that is provided by members of The Canadian
              Real Estate Association (CREA), who are responsible for its
              accuracy. CREA reproduces and distributes this information as a
              service for its members and assumes no responsibility for its
              accuracy.
            </OurHistoryText>
            <OurHistoryText style={{ flex: 1 }}>
              This website is operated by Ellicott Realty Inc., Brokerage* who
              is a member of The Canadian Real Estate Association.
            </OurHistoryText>
            <OurHistoryText style={{ flex: 1 }}>
              The listing content on this website is protected by copyright and
              other laws, and is intended solely for the private, non-commercial
              use by individuals. Any other reproduction, distribution or use of
              the content, in whole or in part, is specifically forbidden. The
              prohibited uses include commercial use, "screen scraping",
              "database scraping", and any other activity intended to collect,
              store, reorganize or manipulate data on the pages produced by or
              displayed on this website.
            </OurHistoryText>

            <OurHistoryText style={{ flex: 1 }}>
              The information contained in this website is for general
              information purposes only. The information is provided by Ellicott
              Realty Inc., Brokerage* and while we endeavor to keep the
              information up to date and correct, we make no representations or
              warranties of any kind, express or implied, about the
              completeness, accuracy, reliability, suitability or availability
              with respect to the website or the information, products,
              services, or related graphics contained on the website for any
              purpose. Any reliance you place on such information is therefore
              strictly at your own risk.
            </OurHistoryText>
            <OurHistoryText style={{ flex: 1 }}>
              In no event will we be liable for any loss or damage including
              without limitation, indirect or consequential loss or damage, or
              any loss or damage whatsoever arising from loss of data or profit,
              arising out of, or in connection with, the use of this website.
            </OurHistoryText>
            <OurHistoryText style={{ flex: 1 }}>
              Through this website you are able to link to other websites which
              are not under the control of Ellicott Realty Inc., Brokerage*. We
              have no control over the nature, content and availability of those
              sites. The inclusion of any links does not necessarily imply a
              recommendation or endorse the views expressed within them.
            </OurHistoryText>
            <OurHistoryText style={{ flex: 1 }}>
              Every effort is made to keep the website up and running smoothly.
              However, Ellicott Realty Inc., Brokerage* takes no responsibility
              for, and will not be liable for, the website being temporarily
              unavailable due to technical issues beyond our control.
            </OurHistoryText>
          </FlexDiv>
        </FlexDiv>
      </FlexDiv>
    </OurHistoryWrapper>
  )
}
